<template>
  <main class="mr-4">
    <trac-loading v-if="isLoading"></trac-loading>

    <trac-modal v-if="successModalCtrl" :cancel="false">
      <div class="flex flex-col justify-center items-center p-6 gap-6">
        <img
          width="200"
          src="./../../../assets/svg/pin-management/success-icon.svg"
          alt=""
        />
        <p class="text-xs text-center">You have succesfully added pin.</p>
        <button
          class="border-none bg-none text-primaryBlue p-4 outline-none text-xs"
          @click="completedPinUpdate">
          Done
        </button>
      </div>
    </trac-modal>

    <trac-modal v-if="isLoading === false && bvnAndDocumentVerified === false" :cancel="false">
      <div class="flex flex-col justify-center items-center">
        <img src="@/assets/pin-check-warning.svg" class="w-20 h-20" alt="" />

        <h1 class="font-bold my-6 text-center">Complete KYC to create <br> Wallet PIN</h1>

        <p class="text-center tracking-normal text-xs mb-8 -mt-2 px-8">
          You will need to complete your KYC to be <br> able to create wallet PIN.
        </p>
        <trac-button class="w-32 mt-2 mb-6" @button-clicked="$router.push({name: 'KycProfile'})">
          <span class="px-12 text-center uppercase"
            >Complete KYC</span
          >
        </trac-button>

        <!-- <button
          class="bg-none border-none text-primaryBlue text-xs outline-none font-semibold"
        >
          Skip to Settlement Preference
        </button> -->
      </div>
    </trac-modal>

    <trac-modal
      v-if="skipPinInputModalCtrl"
      @close="skipPinInputModalCtrl = false"
    >
      <div class="flex flex-col justify-center items-center p-6 gap-6">
        <img width="200" src="./../../../assets/svg/alert.svg" alt="" />
        <p class="text-xs text-center px-10">
          Please note that <strong>Instant settlement</strong> will not be
          available for you as you don't have a pin yet.
        </p>

        <button
          class="border-none bg-none text-primaryBlue p-4 outline-none text-xs"
          @click="updateSettlementOption"
        >
          I understand
        </button>
      </div>
    </trac-modal>

    <trac-back-button>Back</trac-back-button>

    <h3 class="my-8 font-bold text-sm">KYC (Set New PIN)</h3>

    <div
      v-if="bvnAndDocumentVerified"
      class="shadow-lg rounded-md p-4 pt-8 md:w-9/12 mx-auto my-8 md:px-12 flex flex-col items-center py-16"
    >
      <h3 class="my-5 font-bold text-primaryBlue">{{ header }}</h3>
      <p class="text-xs md:w-128 text-center">
        {{ content }}
      </p>

      <p class="text-xs my-5 font-bold mt-8">{{ tip }}</p>
      <div class="my-4 grid grid-cols-4 gap-3 w-56">
        <input
          :type="$route.query.ops === 'enter_otp' ? 'number' : 'password'"
          class="border p-3 rounded-sm text-center"
          @keyup="enterNumber"
          v-model.trim="pin.input1"
        />
        <input
          :type="$route.query.ops === 'enter_otp' ? 'number' : 'password'"
          class="border p-3 rounded-sm text-center"
          @keyup="enterNumber"
          v-model.trim="pin.input2"
        />
        <input
          :type="$route.query.ops === 'enter_otp' ? 'number' : 'password'"
          class="border p-3 rounded-sm text-center"
          @keyup="enterNumber"
          v-model.trim="pin.input3"
        />
        <input
          :type="$route.query.ops === 'enter_otp' ? 'number' : 'password'"
          class="border p-3 rounded-sm text-center"
          @keyup="enterNumber"
          v-model.trim="pin.input4"
        />
      </div>

      <button
        v-if="$route.query.onboarding && $route.query.ops === 'new_pin'"
        @click="skipPinInput"
        class="bg-none text-primaryBlue outline-none block mt-6 text-xs font-semibold"
      >
        Skip
      </button>

      <trac-button
        :disabled="checkPin.length !== 4"
        class="w-full max-w-xs my-12"
        @button-clicked="makeRequest"
        ><div class="py-1">{{ buttonText }}</div></trac-button
      >
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_DATA } from "../../../browser-db-config/localStorage";
import { eventBus } from "./../../../eventBus";

export default {
  data() {
    return {
      isLoading: false,
      successModalCtrl: false,
      skipPinInputModalCtrl: false,
      savedPIN: "",
      walletDetails: null,
      status: [],
      bvnAndDocumentVerified: false,
      pin: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
      },
    };
  },
  async created() {
    this.isLoading = true;
    if (!this.$route.query.onboarding) {
      await this.fetchWalletDetails();
    }
    await this.kycCheck().then((data) => {
      this.bvnAndDocumentVerified = this.checkBvnAndDocument(data)
    })
    this.isLoading = false;
  },
  mounted() {
    if (this.$route.query.ops === "confirm_new_pin" && this.savedPIN === "") {
      this.$router.replace({
        name: "ConfirmPinInput",
        query: this.$route.query.onboarding
          ? {
              ops: "new_pin",
              onboarding: true,
            }
          : {
              ops: "new_pin",
            },
      });
    }
  },
  computed: {
    checkPin() {
      return (
        this.pin.input1 + this.pin.input2 + this.pin.input3 + this.pin.input4
      );
    },
    nextRouteDetails() {
      switch (this.$route.query.ops) {
        case "enter_otp":
          return "new_pin";
        case "new_pin":
          return "confirm_new_pin";
        case "old_pin":
          return "new_pin";
        default:
          return "";
      }
    },
    header() {
      switch (this.$route.query.ops) {
        case "enter_otp":
          return "Enter OTP to change your wallet pin";
        case "old_pin":
          return "Provide your old Pin";
        case "new_pin":
          return "Set your new pin";
        case "confirm_new_pin":
          return "Confirm your wallet pin";
        default:
          return "";
      }
    },
    buttonText() {
      switch (this.$route.query.ops) {
        case "enter_otp":
          return "CONFIRM PIN";
        case "old_pin":
          return "Continue";
        case "new_pin":
          return "CONFIRM PIN";
        case "confirm_new_pin":
          return "Submit";
        default:
          return "CONFIRM PIN";
      }
    },
    tip() {
      switch (this.$route.query.ops) {
        case "enter_otp":
          return "enter OTP";
        case "old_pin":
          return "Provide your old Pin";
        case "new_pin":
          return "enter Pin";
        case "confirm_new_pin":
          return "RE-enter Pin";
        default:
          return "enter Pin";
      }
    },
    content() {
      switch (this.$route.query.ops) {
        case "enter_otp":
          return "Please enter the 4-digit code sent to your phone number.";
        case "old_pin":
          return `Hi ${
            GET_USER_BUSINESS_DATA().name
          }, kindly provide your old wallet pin. Your wallet pin is as vital as your wallet itself and should be held by you alone.`;
        case "new_pin":
          return `Hi ${
            GET_USER_BUSINESS_DATA().name
          }, kindly set up your pin. Your wallet pin is as vital as your wallet itself and should be held by you alone.`;
        case "confirm_new_pin":
          return `Hi ${
            GET_USER_BUSINESS_DATA().name
          }, your wallet pin is as vital as your wallet itself and should be held by you alone.`;
        default:
          return "";
      }
    },
  },
  methods: {
    async kycCheck() {
      let result = await this.$store.dispatch("VERIFY_KYC");
      console.log('kycstatus ->', this.status)
      // debugger
      return result.data || []
    },
    checkBvnAndDocument(st) {
      const Bvn = st.find(({ name }) => name === "document")
      const Identity = st.find(({ name }) => name === "bvn")
      const hasBvn = Bvn && (Bvn.status === "VERIFIED" || Bvn.status === "OVERRIDE")
      const hasIdentity = Identity && (Identity.status === "VERIFIED" || Identity.status === "OVERRIDE")
      const allChecks = hasBvn && hasIdentity
      return allChecks
    },
    skipPinInput() {
      this.skipPinInputModalCtrl = true;
    },
    skipPinUpdate() {
      this.$router.replace({
        name: "Kyc",
      });
    },
    async updateSettlementOption() {
      this.isLoading = true;
      const res = await this.$store.dispatch("UPDATE_SETTLEMENT_TYPE", {
        settlement_type: "sameday",
      });

      if (res.status) {
        this.skipPinUpdate();
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message,
        });
      }
      this.isLoading = false;
    },
    async fetchWalletDetails() {
      // this.walletDetails = this.$store.getters["GET_WALLET_DETAILS"] || {})[
      //   "data"
      // ];

      const res = await this.$store.dispatch("FETCH_WALLET_DETAILS");
      // const res = this.$store.getters["GET_WALLET_DETAILS"];
      if (res.status) {
        this.walletDetails = res["data"];
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "Couldn't fetch wallet details.",
        });
      }
      // if (!this.walletDetails) {

      //   console.log(this.walletDetails);
      // }
    },
    completedPinUpdate() {
      this.successModalCtrl = false;
      if (this.$route.query.onboarding) {
        this.$router.replace({
          name: "AddSettlementPreference",
          query: {
            ops: this.nextRouteDetails,
            onboarding: true,
          },
        });
      } else {
        this.$router.go(-2);
      }
    },
    enterNumber(e) {
      const target = e.target;

      if (
        target.nextSibling &&
        target.nextSibling.localName === "input" &&
        target.value.length > 0
      ) {
        target.nextSibling.focus();
      } else if (
        target == target.parentElement.lastChild &&
        target.value.length > 0
      ) {
        target.blur();
      }
    },
    handleResponse(res) {
      if (res.status) {
        this.savedPIN = this.checkPin;

        this.pin = {
          input1: "",
          input2: "",
          input3: "",
          input4: "",
        };

        this.changePage();
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message,
        });
      }
    },
    async makeRequest() {
      this.isLoading = true;
      if (this.$route.query.ops === "old_pin") {
        const res = await this.$store.dispatch("VERIFY_USER_PIN", {
          pin: this.checkPin,
        });

        this.handleResponse(res);
      } else if (this.$route.query.ops === "new_pin") {
        this.handleResponse({
          status: true,
        });
      } else if (this.$route.query.ops === "confirm_new_pin") {
        if (this.savedPIN !== this.checkPin) {
          eventBus.$emit("trac-alert", {
            message: "Confirmation pin doesn't match previously entered pin.",
          });
          this.isLoading = false;
          return;
        }
        const res = await this.$store.dispatch("SET_NEW_USER_PIN", {
          pin: this.checkPin,
        });

        if (this.$route.query.onboarding || !this.walletDetails) {
          const res = await this.$store.dispatch("CREATE_WALLET", {
            pin: this.checkPin,
          });
        }

        this.handleResponse(res);
      } else if (this.$route.query.ops === "enter_otp") {
        // Check entered OTP
        const res = await this.$store.dispatch("VERIFY_CHANGE_PIN_OTP", {
          pin: this.checkPin,
        });

        if (res.status) {
          this.pin = {
            input1: "",
            input2: "",
            input3: "",
            input4: "",
          };

          this.$router.replace({
            name: "ConfirmPinInput",
            query: {
              ops: "new_pin",
            },
          });
        } else {
          eventBus.$emit("trac-alert", {
            message: res.message || "Error: Unable to verify OTP.",
          });
        }
      }

      this.isLoading = false;
    },
    changePage() {
      const onboarding = this.$route.query.onboarding;
      if (this.$route.query.ops == "confirm_new_pin") {
        this.successModalCtrl = true;
      } else if (this.$route.query.ops === "new_pin") {
        this.$router.replace({
          name: "ConfirmPinInput",
          query: onboarding
            ? {
                ops: this.nextRouteDetails,
                onboarding: true,
              }
            : {
                ops: this.nextRouteDetails,
              },
        });
      } else {
        this.$router.replace({
          name: "ConfirmPinInput",
          query: onboarding
            ? {
                ops: this.nextRouteDetails,
                onboarding: true,
              }
            : {
                ops: this.nextRouteDetails,
              },
        });
      }
    },
  },
};
</script>

<style>
</style>